import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"

const Consume = () => {
  const history = useHistory()
  const { url, id = "" } = useParams()

  useEffect(() => {
    history.replace(`/${url}/${id}`, {
      hideNav: true
    })
  }, [history, url, id])

  return null
}

export default Consume
