import React from "react"
import PropTypes from "prop-types"
import { MESSAGE_CARD_TYPES } from "shared/constants"
import styles from "./SelectRate.module.scss"

const UnavailableRatesCard = ({ type = MESSAGE_CARD_TYPES.unavailable, text }) => {
  let headline = "Unavailable"
  let message =
    "There are no parking spots available for reservation. Please select another date or check back later for availability."

  if (type === MESSAGE_CARD_TYPES.notification) {
    headline = ""
    message = text
  }

  if (type === MESSAGE_CARD_TYPES.soldOut) {
    headline = "Sold Out"
    message =
      "Parking lot is sold out! Please select another date or check back later for availability."
  }

  return (
    <div className={styles.emptyRatesWrapper}>
      <div className={styles.emptyRatesHeadline}>{headline}</div>
      <div>{message}</div>
    </div>
  )
}

UnavailableRatesCard.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string
}

export default UnavailableRatesCard
