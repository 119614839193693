import qs from "query-string"
import { AUTH_KEY } from "shared/constants"

export const generateHonkRedirectQuery = (url, { destUrl, originUrl = "" }) => {
  const encodedDestUrl = encodeURIComponent(destUrl)
  const encodedOriginUrl = encodeURIComponent(originUrl)
  const r = encodeURIComponent(
    `${url}?destUrl=${encodedDestUrl}&originUrl=${encodedOriginUrl}`
  )

  const oaTag = localStorage.getItem(AUTH_KEY)

  return qs.stringify({ r, oaTag })
}

// /redirect-to-honk?oaTag=:oaTag&r=/checkout/:cartId?destUrl=https://alta.honkmobile.com/return-to-honk
export const generateRedirectUrl = (url, { destUrl, originUrl }) => {
  return {
    pathname: "/redirect-to-honk",
    search: generateHonkRedirectQuery(url, { destUrl, originUrl })
  }
}

export const avoidPreRedirectUrl = (url, { destUrl, originUrl }) => {
  return {
    pathname: `${process.env.REACT_APP_COREAPP_BASE}/consume`,
    search: generateHonkRedirectQuery(url, { destUrl, originUrl })
  }
}
