import { gql } from "@apollo/client"

export const Logout = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      errors
    }
  }
`

export const Account = gql`
  query Account {
    me {
      emailAddress
    }
  }
`
