import { gql } from "@apollo/client"

export const Signup = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input) {
      account {
        language
      }
      userSession {
        oaTag
      }
      errors
    }
  }
`
