import classNames from "classnames"
import PropTypes from "prop-types"
import get from "lodash/get"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LoginProfileIcon from "./LoginProfileIcon"
import styles from "./Nav.module.scss"

const Nav = ({ hideLogin, hideBackButton, className }) => {
  const [t] = useTranslation()
  const history = useHistory()
  const handleBackNav = history.goBack
  const hideNav = get(history, "location.state.hideNav", false)

  return (
    <nav className={classNames(styles.navbar, className)}>
      <div className={styles.leftButtonContainer}>
        {!hideNav && !hideBackButton && (
          <button
            type="button"
            onClick={handleBackNav}
            className={styles.leftButton}
          >
            <i className="bi-chevron-left" />
            &nbsp;Back
          </button>
        )}
      </div>
      <Link className={styles.title} to="/">
        {t("title")}
      </Link>
      <div className={styles.rightButtonContainer}>
        {!hideLogin && <LoginProfileIcon />}
      </div>
    </nav>
  )
}

Nav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideLogin: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  className: PropTypes.string
}

export default Nav
