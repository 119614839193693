import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./Headline.module.scss"

const Headline = ({ children, className }) => (
  <div className={classNames(styles.headline, className)}>{children}</div>
)

Headline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string
}

export default Headline
