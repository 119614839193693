import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import RSVPConfig from "RSVPConfig"
import Card from "components/shared/Card"
import Modal from "components/shared/Modal/Modal"
import infoIcon from "./assets/info.svg"

import styles from "./MoreInfoCard.module.scss"

const MoreInfoCard = () => {
  const { t } = useTranslation()
  const { features } = RSVPConfig
  const [showModal, setShowModal] = useState(false)

  if (!features.moreInformation) {
    return null
  }

  const handleOpenModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const renderMoreInfoHeader = () => (
    <div className="d-flex align-items-center mb-3">
      <img src={infoIcon} className="me-1" alt="info" />
      <h2 className={classNames(styles.modalHeader, "fw-bold")}>
        More Information
      </h2>
    </div>
  )

  return (
    <Card className={classNames(styles.infoCard)}>
      {renderMoreInfoHeader()}
      <>
        <div
          className={styles.infoText}
          dangerouslySetInnerHTML={{ __html: t("moreInfo") }}
        />
        <button
          onClick={handleOpenModal}
          className={styles.showMore}
          type="button"
        >
          See more
        </button>
      </>
      <Modal isOpen={showModal} onClose={handleCloseModal}>
        {renderMoreInfoHeader()}
        <div className={styles.modalContent}>
          <div
            className={styles.modalInfoText}
            dangerouslySetInnerHTML={{ __html: t("moreInfo") }}
          />
        </div>
        <button
          onClick={handleCloseModal}
          type="button"
          className={styles.closeButton}
        >
          Close
        </button>
      </Modal>
    </Card>
  )
}

export default MoreInfoCard
