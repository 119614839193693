import { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import qs from "query-string"
import Helmet from "react-helmet"
import FullPageLoader from "components/shared/FullPageLoader"

const PostPurchase = () => {
  const { search } = useLocation()
  const history = useHistory()
  const [t] = useTranslation()

  useEffect(() => {
    const { parkingSessionId } = qs.parse(search)
    const timer = setTimeout(() => {
      // Unclear - make the query here in this current route,
      // or on the one below?
      history.replace(`parking-reservation/${parkingSessionId}`, {
        hideNav: true
      })
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [history, search])

  return (
    <>
      <Helmet title="Redirecting Back" />
      <FullPageLoader message={`Redirecting to ${t("title")}`} />
    </>
  )
}

export default PostPurchase
