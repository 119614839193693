import { DateTime } from "luxon"

// Takes a luxon DateTime instance as the argument
export const formatToDayStartISO = (datetime, options) => {
  const { timezone } = options
  return datetime
    .startOf("day")
    .setZone(timezone, { keepLocalTime: true })
    .toISO({ suppressMilliseconds: true })
}

// Takes an ISO date string and formats it to WEEKDAY MONTH DAY, YEAR
export const formatDate = (date, options) => {
  const { timezone } = options
  return DateTime.fromISO(date)
    .setZone(timezone, { keepLocalTime: true })
    .toFormat("ccc LLL dd, yyyy")
    .toUpperCase()
}

export const formatShortDate = (date, options) => {
  const { timezone } = options
  return DateTime.fromISO(date)
    .setZone(timezone, { keepLocalTime: true })
    .toFormat("LLL d/yy")
}
