import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./Layout.module.scss"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <div className="d-flex flex-column h-100">
      <div className={classNames("flex-1", styles.body)}>{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
