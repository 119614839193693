import React from "react"
import ReactDOM from "react-dom"
import { ApolloProvider } from "@apollo/client"
import client from "./apolloClient"

import "@csstools/normalize.css" // see https://github.com/facebook/create-react-app/issues/9710
import "./index.scss"
import "./i18n"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
