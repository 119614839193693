import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { STEPS } from "shared/constants"
import PartialPageLoader from "components/shared/PartialPageLoader"

import styles from "./ExpandableCard.module.scss"

const ExpandableCard = ({
  step,
  currentStep,
  children,
  title,
  subtitle,
  buttonText,
  onButtonClick,
  hide = false,
  isLoading = false
}) => {
  // active means the card is currently open
  const active = step === currentStep
  // display is used to determine if the button should be displayed
  // we don't want to display the button if the card is active or
  // if the current step is zone
  // we also don't want to display the button when rsvp portal has only one zone (hide=true)
  const display = !active && currentStep !== STEPS.zone && !hide

  return (
    <div>
      <div
        className={classNames(styles.titleBox, {
          [styles.active]: active,
          [styles.disabled]: !active
        })}
      >
        <div>
          <div>{title}</div>
          {/* we don't want to display the subtitle if the card is active or if the current step is zone */}
          {!active && currentStep !== STEPS.zone && (
            <div className={styles.subtitle}>{subtitle}</div>
          )}
        </div>
        {display && (
          <button
            onClick={onButtonClick}
            className={styles.button}
            type="button"
          >
            {buttonText}
          </button>
        )}
      </div>
      {active && (
        <div
          className={classNames(styles.animatedContainer, {
            [styles.containerActive]: active,
            [styles.containerDisabled]: !active
          })}
        >
          {/* we display a loader if the card is loading, only if the card is active */}
          {isLoading ? <PartialPageLoader /> : children}
        </div>
      )}
    </div>
  )
}

ExpandableCard.propTypes = {
  step: PropTypes.string,
  currentStep: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  hide: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default ExpandableCard
