import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import RSVPConfig from "RSVPConfig"

import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    load: "languageOnly",
    react: {
      useSuspense: false
    },
    debug: false,
    ns: RSVPConfig.name,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
