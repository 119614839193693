import isEmpty from "lodash/isEmpty"
import omit from "lodash/omit"
import some from "lodash/some"
import filter from "lodash/filter"

import { BEHAVIOUR_TYPE } from "shared/constants"

/**
 * availability data structure
  const dayData = {
    status: {
      sold_out: <bool>,
      unavailable: <bool>,
      reservation_not_needed: <bool>
    },
    <rate hashid>: {
      available: <bool>,
      hashid: <rate hashid>,
      description: <rate description>,
      notification: <bool>,
      price: <rate price>
  }
 */

// Returns true if any public rate is unavailable
export const isAnyPublicRateUnavailable = (availability) => {
  if (isEmpty(availability)) return false

  const ratesData = omit(availability, ["status"])
  return some(ratesData, (rate) => !rate.available)
}

// Returns true if the availability status is sold out
export const isSoldOut = (availability) => {
  if (isEmpty(availability)) return false

  return availability.status.sold_out
}

// Returns true if any rate is a notification rate
export const isNotificationRate = (rates) => {
  if (isEmpty(rates)) return false

  return some(
    rates,
    (rate) => rate.behaviourType === BEHAVIOUR_TYPE.notification
  )
}

// Returns true if any rate is a notification rate and is free rate
// (reservation not needed)
export const isNotNeededNotificationRate = (rates) => {
  if (isEmpty(rates)) return false

  return some(
    rates,
    (rate) =>
      rate.behaviourType === BEHAVIOUR_TYPE.notification && rate.freeFlag
  )
}

// Returns an array of unavailable private rates
export const getUnavailableRates = (availability) => {
  if (isEmpty(availability)) return []

  const ratesData = omit(availability, ["status"])
  const unavailableRates = filter(ratesData, (rate) => !rate.available)

  return unavailableRates
}
