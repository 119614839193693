import PropTypes from "prop-types"
import Nav from "components/shared/Nav"
import Headline from "./Headline"

import styles from "./Hero.module.scss"

const Hero = ({ children, hideLogin }) => {
  return (
    <div className={styles.hero}>
      <Nav hideLogin={hideLogin} />
      <div className={styles.content}>{children}</div>
    </div>
  )
}

Hero.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideLogin: PropTypes.bool
}

Hero.defaultProps = {
  hideLogin: false
}
// Convenience namespacing for accessing subcomponents
Hero.Headline = Headline
Hero.Nav = Nav

export default Hero
