import React from "react"
import { useReactiveVar } from "@apollo/client"
import { selectedDayVar } from "cache"
import { DateTime } from "luxon"
import { calendarDateRange } from "shared/helpers/calendarDateRange"
import { formatToDayStartISO } from "shared/formatting/datetime"
import PropTypes from "prop-types"

import styles from "./ParkingSelection.module.scss"

const DateNavigation = ({ onCartStartTimeChange, timezone }) => {
  const selectedDay = useReactiveVar(selectedDayVar)
  const setSelectedDay = selectedDayVar
  const startOfToday = formatToDayStartISO(DateTime.now(), { timezone })
  const { minDate, maxDate } = calendarDateRange()
  // Format of the min and max date needs to be the same as the selected day
  const selectedDayDateTime = DateTime.fromISO(selectedDay)
  const isOnMinDate = selectedDayDateTime.hasSame(minDate, "day") // When the selected day is the minimum allowed date
  const isOnMaxDate = selectedDayDateTime.hasSame(maxDate, "day") // When the selected day is the maximum allowed date

  // This function will set the selected day to the previous day
  // if the selected day is not the current day
  const handlePreviousDayClick = () => {
    if (selectedDay === startOfToday) return

    const previousDay = DateTime.fromISO(selectedDay, { zone: timezone }).minus(
      {
        days: 1
      }
    )
    const formatedDate = formatToDayStartISO(previousDay, { timezone })

    setSelectedDay(formatedDate)
    onCartStartTimeChange(formatedDate)
  }

  // This function will set the selected day to the next day
  const handleNextDayClick = () => {
    const nextDay = DateTime.fromISO(selectedDay, { zone: timezone }).plus({
      days: 1
    })
    const formatedDate = formatToDayStartISO(nextDay, { timezone })

    setSelectedDay(formatedDate)
    onCartStartTimeChange(formatedDate)
  }

  return (
    <div className={styles.changeDay}>
      <button
        onClick={handlePreviousDayClick}
        disabled={isOnMinDate} // Disable the button if the selected day is the minimum allowed date
        type="button"
      >
        &lt; Previous Day
      </button>
      <button
        onClick={handleNextDayClick}
        disabled={isOnMaxDate} // Disable the button if the selected day is the maximum allowed date
        type="button"
      >
        Next Day &gt;
      </button>
    </div>
  )
}

DateNavigation.propTypes = {
  timezone: PropTypes.string,
  onCartStartTimeChange: PropTypes.func
}

export default DateNavigation
