import React from "react"
import ReactModal from "react-modal"
import PropTypes from "prop-types"
import closeIcon from "../assets/close-icon.svg"

import styles from "./Modal.module.scss"

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContainer}
      overlayClassName={styles.modalOverlay}
    >
      <button className={styles.closeButton} onClick={onClose} type="button">
        <img src={closeIcon} alt="close" />
      </button>
      {children}
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
}

export default Modal
