import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./PartialPageLoader.module.scss"

const PartialPageLoader = () => {
  return (
    <div
      className={classNames(styles.wrapper, "d-flex justify-content-center")}
    >
      <div className="spinner-border text-primary mb-2" role="status" />
    </div>
  )
}

PartialPageLoader.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default PartialPageLoader
