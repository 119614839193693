const config = {
  name: "build-test",
  features: {
    accessCodes: false,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true,
    userName: false
  },
  parkingSession: {
    // 7hours, i.e at midnight
    cancellationDeltaMs: 25200000,
    // Start time offset from midnight, i.e. 7 am
    startOffsetHrs: 7,
    // Start date offset from today (0 = today)
    startOffsetDate: 1, // Tomorrow
    // End date offset from start time
    endOffsetDate: 160
  }
}
export default config
