import { useState } from "react"
import { Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import classNames from "classnames"
import { Helmet } from "react-helmet"
import { Form } from "react-bootstrap"
import { errorsVar, isLoggedInVar } from "cache"
import { AUTH_KEY } from "shared/constants"
import Hero from "components/shared/Hero"
import FullPageLoader from "components/shared/FullPageLoader"
import ErrorToast from "components/shared/ErrorToast"
import { useAuthRedirectEffect } from "./hooks"
import { Login as LogInUser } from "./operations"

import styles from "./Login.module.scss"

const Login = () => {
  useAuthRedirectEffect()
  const onCompleted = ({ login }) => {
    const oaTag = get(login, ["userSession", "oaTag"])
    const { errors } = login

    if (!isEmpty(errors)) {
      errorsVar(errors)
    }

    if (oaTag) {
      localStorage.setItem(AUTH_KEY, oaTag)
      errorsVar(null)
      isLoggedInVar(true)
    }
  }

  const [loginUser, { loading }] = useMutation(LogInUser, {
    onCompleted
  })

  const [emailAddress, setEmailAddress] = useState("")
  const [password, setPassword] = useState("")
  const [validated, setValidated] = useState(false)

  const handleSubmit = (e) => {
    const form = e.target

    e.preventDefault()
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else {
      loginUser({
        variables: {
          input: { emailAddress, password }
        }
      })
    }
    setValidated(true)
  }

  if (loading) return <FullPageLoader message="Logging in" />

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-shrink-0">
        <Helmet title="Login" />
        <Hero hideLogin>
          <Hero.Headline>
            Log In with HONK
            <br />
            to Continue
          </Hero.Headline>
        </Hero>

        <div className={classNames(styles.formWrapper, "container-sm")}>
          <div className="row justify-content-center">
            <div className="col-md-8 col-xl-6">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="emailAddress" className="form-label fw-bold">
                    Email address
                  </label>
                  <input
                    id="emailAddress"
                    className="form-control"
                    placeholder="Enter your email address"
                    value={emailAddress}
                    type="email"
                    required
                    onChange={(e) => {
                      setEmailAddress(e.target.value)
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label fw-bold">
                    Password
                  </label>
                  <input
                    id="password"
                    className="form-control"
                    placeholder="Enter your password"
                    type="password"
                    value={password}
                    required
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid password.
                  </Form.Control.Feedback>
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className={classNames(
                      styles.submitButton,
                      "btn btn-primary btn-lg mt-3 mb-4 fw-bold"
                    )}
                  >
                    Login
                  </button>
                </div>
              </Form>
              <a
                href={`${process.env.REACT_APP_COREAPP_BASE}/forgot-password`}
                target="_blank"
                rel="noreferrer"
                className="d-block text-center"
              >
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto footer text-center py-3">
        Don&apos;t have a HONK account?&nbsp;
        <Link to="/signup" replace>
          Sign Up
        </Link>
      </div>
      <ErrorToast />
    </div>
  )
}

export default Login
