import { useRef } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Footer.module.scss"

const Footer = () => {
  const { t } = useTranslation()
  const footerRef = useRef(null)
  const parkName = t("footer.text")

  const renderLink = () => (
    <a
      className={styles.link}
      href={t("footer.url")}
      target="_blank"
      rel="noreferrer"
    >
      {parkName}
    </a>
  )

  const poweredByHonk = () => <span>Powered by HONK</span>

  return (
    <footer className={styles.footer} ref={footerRef}>
      {parkName.length > 15 ? (
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex justify-content-center gap-1 mb-2">
            <span>Visit</span>
            {renderLink()}
          </div>
          {poweredByHonk()}
        </div>
      ) : (
        <>
          <span>Visit</span>
          {renderLink()}
          <span> • </span>
          {poweredByHonk()}
        </>
      )}
    </footer>
  )
}

export default Footer
