import { gql } from "@apollo/client"

export const RsvpPortalParkingSessions = gql`
  query RsvpPortalParkingSessions($rsvpPortalId: ID!) {
    rsvpPortalParkingSessions(rsvpPortalId: $rsvpPortalId) {
      hashid
      rateName
      startTime
      endTime
      voided
      transactionHistory {
        id
        total
      }
      zone {
        zoneId
        hashid
        address {
          street
          city
        }
      }
    }
  }
`
