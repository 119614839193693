import { gql } from "@apollo/client"

export const GetParkingSession = gql`
  query GetParkingSession($id: ID!) {
    parkingSession(id: $id) {
      id
      hashid
      rateName
      startTime
      endTime
      voided
      plateNumber
      numAllowedPlateSwitches
      transactionHistory {
        id
        invoiceNumber
      }
      zone {
        zoneId
        hashid
        timezone
        address {
          street
          city
          lat
          lon
        }
        entranceLocation {
          lat
          lon
        }
      }
      promoCode {
        shortDesc
        redeemCode
        startDate
        expiry
      }
    }
  }
`

export const CancelParkingSession = gql`
  mutation CancelParkingSession($input: CancelParkingSessionInput!) {
    cancelParkingSession(input: $input) {
      parkingSession {
        hashid
        voided
      }
      errors
    }
  }
`
