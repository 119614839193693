import React, { useState, useEffect } from "react"
import { calendarDateRange } from "shared/helpers/calendarDateRange"
import {
  Datepicker,
  CalendarPrev,
  CalendarNav,
  CalendarNext
} from "@mobiscroll/react"
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import "./Calendar.scss"

const Calendar = (props) => {
  const [pages, setPages] = useState(window.innerWidth > 600 ? 2 : 1)

  useEffect(() => {
    const handleResize = () => {
      setPages(window.innerWidth > 600 ? 2 : 1)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Datepicker {...props} pages={pages} />
}

const { minDate, maxDate } = calendarDateRange()
const minStartDate = minDate.toISODate()
const maxStartDate = maxDate.toISODate()

Datepicker.defaultProps = {
  theme: "ios",
  themeVariant: "light",
  display: "inline",
  showOuterDays: false,
  min: minStartDate,
  max: maxStartDate,
  renderCalendarHeader: () => (
    <>
      <CalendarPrev className="custom-prev" />
      <CalendarNav className="custom-nav" />
      <CalendarNext className="custom-next" />
    </>
  )
}

export default Calendar
