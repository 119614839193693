/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react"
import { useReactiveVar, useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import get from "lodash/get"
import find from "lodash/find"
import isEmpty from "lodash/isEmpty"
import includes from "lodash/includes"
import filter from "lodash/filter"
import { zoneIdVar, cartPromoCodeVar, cartIdVar } from "cache"
import { STEPS } from "shared/constants"
import Card from "components/shared/Card"
import { ReadCurrentAccountPromoCodes } from "components/AccountPromoCodes/operations"
import forwardArrow from "components/Home/assets/forward-arrow.svg"
import { useRsvpPortal } from "./hooks"
import ExpandableCard from "./ExpandableCard"

import styles from "./SelectZone.module.scss"

const SelectZone = ({ step, setStep }) => {
  const [zoneAddress, setZoneAddress] = useState("")
  const [hideEditButton, setHideEditButton] = useState(false)

  const { loading: portalLoading, data: portalData } = useRsvpPortal()
  const zones = get(portalData, "rsvpPortal.zones", [])

  const promoCode = useReactiveVar(cartPromoCodeVar)

  const { data: currentCodesData } = useQuery(ReadCurrentAccountPromoCodes, {
    variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID }
  })
  const currentAccountPromoCodes = get(
    currentCodesData,
    "currentAccountPromoCodes"
  )
  const promoCodeData = find(currentAccountPromoCodes, {
    redeemCode: promoCode
  })
  const promoCodeZones = get(promoCodeData, "locationIds", [])

  // Filter portalZones to include only those with zoneIds present in promo code locationIds
  const matchingZones = filter(zones, (zone) =>
    includes(promoCodeZones, zone.zoneId)
  )

  // If promo code is empty, show all zones
  // If promo code is present, show only zones that match the promo code locationIds
  const parkingLocations = useMemo(() => {
    return isEmpty(promoCode) ? zones : matchingZones
  }, [promoCode, promoCodeZones, zones])

  // Handle the zone selection
  // It sets the zoneIdVar and zoneAddressVar
  // and it switches the step to the next step
  const handleZoneSelection = (zone) => {
    if (zoneIdVar() === zone.hashid) {
      setStep(STEPS.date)
      return
    }

    // Reset the cart id when selecting different zone
    // If not, privateParkingAvailability will run twice, with old cart and new cart
    cartIdVar(null)
    zoneIdVar(zone.hashid)
    setZoneAddress(zone.address.street)
    setStep(STEPS.date)
  }

  // Handle initial pre-selection if there is only one zone
  useEffect(() => {
    if (parkingLocations.length === 1) {
      const selectedZone = parkingLocations[0]

      handleZoneSelection(selectedZone)
      setHideEditButton(true)
    }
  }, [parkingLocations])

  // Handle initial pre-selection if there is a promo code with only one zone
  useEffect(() => {
    if (!isEmpty(promoCode) && promoCodeZones.length === 1) {
      const selectedZone = find(parkingLocations, { zoneId: promoCodeZones[0] })

      if (!isEmpty(selectedZone)) {
        handleZoneSelection(selectedZone)
        setHideEditButton(true)
      }
    }
  }, [parkingLocations])

  // Handle zone change
  const handleZoneChange = () => {
    setStep(STEPS.zone)
  }

  // Render card content
  const handleCardContent = () => {
    return parkingLocations.map((zone) => (
      <Card
        className={styles.card}
        key={zone.hashid}
        onClick={() => handleZoneSelection(zone)}
      >
        <div>{zone.address.street.toUpperCase()}</div>
        <img src={forwardArrow} alt="arrow" className={styles.arrowIcon} />
      </Card>
    ))
  }

  return (
    <div className="h-100">
      <ExpandableCard
        currentStep={step}
        step={STEPS.zone}
        title="1. Parking location"
        subtitle={zoneAddress.toUpperCase()}
        buttonText="Edit"
        onButtonClick={handleZoneChange}
        hide={hideEditButton}
        isLoading={portalLoading}
      >
        <div className={styles.wrapper}>{handleCardContent()}</div>
      </ExpandableCard>
    </div>
  )
}

SelectZone.propTypes = {
  step: PropTypes.string,
  setStep: PropTypes.func
}

export default SelectZone
/* eslint-enable react-hooks/exhaustive-deps */
