import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import RSVPConfig from "RSVPConfig"

import styles from "./ParkingSelection.module.scss"

const RedeemParkingCodeLink = ({ display }) => {
  const { features } = RSVPConfig
  if (!features.accessCodes || !display) {
    return null
  }

  return (
    <div className={styles.redeemCodeWrapper}>
      Got a parking code?
      <Link className={styles.redeemCodeLink} to="/code">
        Redeem your code
      </Link>
    </div>
  )
}

RedeemParkingCodeLink.propTypes = {
  display: PropTypes.bool
}

export default RedeemParkingCodeLink
