export default function formatPrice({ value, currency = "USD" }) {
  const withoutCents = value % 1 === 0

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
    minimumFractionDigits: withoutCents ? 0 : 2
  })

  return formatter.format(value)
}
