import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./Card.module.scss"

const Card = ({ children, className, onClick, open }) => {
  return (
    <div
      onClick={onClick}
      open={open}
      className={classNames(styles.card, className)}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  open: PropTypes.bool
}

export default Card
