import { gql } from "@apollo/client"

export const Login = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      userSession {
        oaTag
      }
      errors
    }
  }
`

export const IsUserLoggedIn = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`
