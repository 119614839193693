const config = {
  name: "heavenly",
  features: {
    accessCodes: true,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true,
    userName: false
  },
  parkingSession: {
    // that day, just before 8am
    cancellationDeltaMs: 0,
    // Start time offset from midnight, i.e. 8 am
    startOffsetHrs: 8,
    // Start date offset from today (0 = today)
    startOffsetDate: 0,
    // End date offset from start time
    endOffsetDate: 300
  },
  signUp: {
    firstNameRequired: false,
    lastNameRequired: false
  }
}
export default config
