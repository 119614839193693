import { useEffect, useCallback } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useLoginState } from "components/Login/hooks"
import isEmpty from "lodash/isEmpty"
import { clearOnLogout } from "apolloClient"
import { Account, Logout } from "./operations"

export const useCheckUserSession = () => {
  const [isLoggedIn] = useLoginState()
  const [logoutUser, { client }] = useMutation(Logout)

  const logout = useCallback(() => {
    clearOnLogout(client)
    logoutUser({
      variables: {
        input: {}
      }
    })
  }, [client, logoutUser])

  const onCompleted = ({ me }) => {
    if (isEmpty(me)) {
      logout()
    }
  }

  const [getCurrentUser] = useLazyQuery(Account, {
    fetchPolicy: "network-only",
    onCompleted
  })

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUser()
    }
  }, [isLoggedIn, getCurrentUser])
}
