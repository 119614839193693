import { DateTime } from "luxon"
import isEmpty from "lodash/isEmpty"
import { selectedDayVar } from "cache"
import RSVPConfig from "RSVPConfig"

// Pretty hacky -- most of the calendar page is about "start-of-day-in-timezone"
// This separates the cart start time from the "start-of-day" behaviour.
// It's  so we can set a default cart start time as an offset from the
// start of the day without having to make adjustments everywhere.
export const cartStartTime = (timestamp, timezone) => {
  const initialTime = DateTime.fromISO(timestamp, { zone: timezone })
  const offsetTime = initialTime.plus({
    hours: Number(RSVPConfig.parkingSession.startOffsetHrs)
  })

  // Days when DST changes, intialTime and offsetTime will be in different DST states
  if (initialTime.isInDST !== offsetTime.isInDST) {
    // When initialTime.isInDST is true while offsetTime.isInDST is false, that is the day when DST ends
    return initialTime.isInDST
      ? offsetTime.plus({ hours: 1 }).toISO({ suppressMilliseconds: true })
      : offsetTime.minus({ hours: 1 }).toISO({ suppressMilliseconds: true })
  }

  return offsetTime.toISO({ suppressMilliseconds: true })
}

export const cartStartDay = () => {
  const selectedDay = selectedDayVar()
  if (!isEmpty(selectedDay)) {
    return DateTime.fromISO(selectedDay)
  }
  return DateTime.now()
}
