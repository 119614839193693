import { useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import { accountPromoCodeVar, errorsVar } from "cache"
import { PROMO_CODE_KEY } from "shared/constants"
import { ClaimAccountPromoCode } from "./operations"

export const useConsumePromoCode = () => {
  const history = useHistory()

  const onCompleted = ({ v2ClaimAccountPromoCode }) => {
    const { errors } = v2ClaimAccountPromoCode

    localStorage.removeItem(PROMO_CODE_KEY)
    if (!isEmpty(errors)) {
      // Don't clear accountPromoCodeVar on success as it's used for errors
      errorsVar(errors)
      history.replace("/code")
    } else {
      errorsVar(null)
      accountPromoCodeVar(null)
    }
  }

  return useMutation(ClaimAccountPromoCode, {
    onCompleted
  })
}
