import RSVPConfig from "RSVPConfig"
import { DateTime } from "luxon"

export const calendarDateRange = () => {
  const minDate = DateTime.now().plus({
    days: RSVPConfig.parkingSession.startOffsetDate
  })
  const maxDate = DateTime.now().plus({
    days: RSVPConfig.parkingSession.endOffsetDate
  })

  return { minDate, maxDate }
}
