import { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { cartIdVar, errorsVar, cartPromoCodeVar, zoneIdVar } from "cache"
import Nav from "components/shared/Nav"
import ErrorToast from "components/shared/ErrorToast"
import { STEPS } from "shared/constants"
import { isNotNeededNotificationRate } from "shared/helpers/availability"

import SelectZone from "./SelectZone"
import SelectDate from "./SelectDate"
import DateNavigation from "./DateNavigation"
import SelectRate from "./SelectRate"
import MoreInfoCard from "./MoreInfoCard"

import { ChangeCartStartTime, GetRates } from "./operations"
import { useRsvpPortalZone } from "./hooks"
import { cartStartTime } from "./helpers"
import codeRedeemIcon from "./assets/redeem-code.svg"

import styles from "./ParkingSelection.module.scss"
import RedeemParkingCodeLink from "./RedeemParkingCodeLink"

const ParkingSelection = () => {
  const [step, setStep] = useState(STEPS.zone)
  const [detailedRatesAvailability, setDetailedRatesAvailability] = useState({})
  const cartId = useReactiveVar(cartIdVar)
  const { data: zoneData } = useRsvpPortalZone()
  const timezone = get(zoneData, "zone.timezone")
  // Get the applied promo code
  const promoCode = useReactiveVar(cartPromoCodeVar)

  // Query to get the rates for the cart
  const [getCartRates, { loading: rateLoading, data: rates }] = useLazyQuery(
    GetRates,
    {
      fetchPolicy: "network-only"
    }
  )

  // Mutation that changes the start time of the cart
  // And fetches the rates for the cart after the start time is changed
  const [changeCartStartTime, { loading: cartLoading }] = useMutation(
    ChangeCartStartTime,
    {
      onCompleted: ({ changeCartStartTime: data }) => {
        const { errors } = data
        if (!isEmpty(errors)) {
          errorsVar(errors)
        } else {
          errorsVar(null)
          getCartRates({
            variables: {
              cartId
            }
          })
        }
      }
    }
  )

  const handleCartStartTimeChange = (date) => {
    changeCartStartTime({
      variables: {
        input: {
          startTime: cartStartTime(date, timezone),
          id: cartId
        }
      }
    })
  }

  useEffect(() => {
    return () => {
      // Clear the promo code when the component unmounts
      cartPromoCodeVar(null)
      // Clear the zoneId when the component unmounts to prevent error when selecting same zone again
      zoneIdVar(null)
    }
  }, [])

  return (
    <div className="h-100">
      <Helmet>
        <title>Select Parking</title>
      </Helmet>
      <Nav />
      <div className={styles.container}>
        <div className={styles.title}>Reserve a parking spot</div>
        {promoCode && (
          <div className={styles.promoCodeContainer}>
            <img src={codeRedeemIcon} alt="promo code" />
            <div className={styles.promoCodeWrapper}>
              <div className={styles.header}>Selected Pass</div>
              <div className={styles.redeemCode}>{promoCode}</div>
            </div>
          </div>
        )}
        <SelectZone step={step} setStep={setStep} />
        <SelectDate
          step={step}
          setStep={setStep}
          onCartStartTimeChange={handleCartStartTimeChange}
          setDetailedRatesAvailability={setDetailedRatesAvailability}
        />
        {step === STEPS.rate && (
          <DateNavigation
            onCartStartTimeChange={handleCartStartTimeChange}
            timezone={timezone}
          />
        )}
        <SelectRate
          step={step}
          rates={rates}
          rateLoading={rateLoading}
          cartLoading={cartLoading}
          detailWithRatesData={detailedRatesAvailability}
        />
        {step === STEPS.rate && (
          <>
            <RedeemParkingCodeLink
              display={
                !isNotNeededNotificationRate(rates?.v2CartRates) && !promoCode
              }
            />
            <MoreInfoCard />
          </>
        )}
      </div>
      <ErrorToast />
    </div>
  )
}

export default ParkingSelection
