import { useQuery, useMutation } from "@apollo/client"
import { useParams, Link } from "react-router-dom"
import classNames from "classnames"
import Helmet from "react-helmet"
import { DateTime } from "luxon"
import toUpper from "lodash/toUpper"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

import RSVPConfig from "RSVPConfig"
import Hero from "components/shared/Hero"
import Card from "components/shared/Card"
import FullPageLoader from "components/shared/FullPageLoader"
import { generateRedirectUrl, avoidPreRedirectUrl } from "shared/helpers/url"
import ErrorToast from "components/shared/ErrorToast"
import { ReadCurrentAccountPromoCodes } from "components/AccountPromoCodes/operations"
import { useForceAuthEffect, useLoginState } from "components/Login/hooks"
import { errorsVar } from "cache"
import { useTranslation } from "react-i18next"

import clock from "./assets/clock.svg"
import mapPin from "./assets/mapPin.svg"
import receipt from "./assets/receipt.svg"
import directions from "./assets/directions.svg"
import vehicle from "./assets/vehicle.svg"
import { CancelParkingSession, GetParkingSession } from "./operations"
import ParkingInstructionsCard from "./ParkingInstructionsCard"

import styles from "./ParkingSession.module.scss"

const googleMapsUrl = ({ lat, lon }) =>
  `https://maps.google.com/maps?saddr=Current Location&daddr=${lat},${lon}`

const ParkingSession = () => {
  useForceAuthEffect()
  const [isLoggedIn] = useLoginState()
  const [t] = useTranslation()

  const { parkingSessionId } = useParams()
  const { loading, data, error } = useQuery(GetParkingSession, {
    variables: { id: parkingSessionId },
    skip: !isLoggedIn
  })

  const { data: currentCodesData } = useQuery(ReadCurrentAccountPromoCodes, {
    variables: { rsvpPortalId: process.env.REACT_APP_RSVP_PORTAL_ID }
  })

  const [cancelSession, { loading: cancelling }] = useMutation(
    CancelParkingSession,
    {
      onCompleted: ({ cancelParkingSession }) => {
        const { errors } = cancelParkingSession
        if (!isEmpty(errors)) {
          errorsVar(errors)
        } else {
          errorsVar(null)
        }
      }
    }
  )

  if (!isLoggedIn || loading)
    return <FullPageLoader message="Loading parking reservation" />
  if (cancelling)
    return <FullPageLoader message="Cancelling parking reservation" />
  if (error) return <ErrorToast />

  const {
    parkingSession: {
      id,
      rateName,
      plateNumber,
      startTime,
      endTime,
      numAllowedPlateSwitches,
      transactionHistory: { invoiceNumber, id: txnId },
      zone: { address, entranceLocation, timezone },
      promoCode,
      voided: isCancelled
    }
  } = data

  // TODO - maybe a selector/field policy?
  const { lat: addressLat, lon: addressLon } = address || {}
  const { lat: entranceLat, lon: entranceLon } = entranceLocation || {}

  let coords = { lat: addressLat, lon: addressLon }
  if (entranceLat && entranceLon) {
    coords = { lat: entranceLat, lon: entranceLon }
  }

  const pageTitle = loading
    ? "Loading Parking Reservation..."
    : `Parking Reservation ${invoiceNumber.toUpperCase()}`

  const formatDate = (iso8601Timestamp) =>
    DateTime.fromISO(iso8601Timestamp)
      .setZone(timezone)
      .toLocaleString(DateTime.DATETIME_MED)

  const renderStatus = () => {
    if (isCancelled) {
      return (
        <Card className={classNames("d-flex px-3 py-2 mb-3", styles.callout)}>
          <div className="pe-3">
            <i className="fs-1 text-primary bi bi-exclamation-circle" />
          </div>
          <div className="d-flex align-items-center fw-bold">
            This parking reservation has been cancelled.
          </div>
        </Card>
      )
    }

    return null
  }

  const cancel = () => {
    cancelSession({
      variables: { input: { id: parkingSessionId } }
    })
  }

  const renderModifiers = () => {
    const isExpired =
      DateTime.fromISO(endTime).setZone(timezone) <
      DateTime.now().setZone(timezone)

    if (isExpired || isCancelled) {
      return null
    }

    // 0 means no switches allowed
    // 1 means you can only register 1 plate, which is your current one
    // -1 means infinite switches
    const switchingAllowed =
      numAllowedPlateSwitches > 1 || numAllowedPlateSwitches === -1

    // If switching is allowed, show the change plate button
    const showChangePlateButton = switchingAllowed && !isCancelled

    const countExplanation =
      numAllowedPlateSwitches === -1
        ? "There is no limit on the number of vehicle plates that can be stored in your account."
        : `Up to ${numAllowedPlateSwitches} vehicle plates may be stored in your account.`

    const cancellationAllowed =
      DateTime.now().setZone(timezone) <
      DateTime.fromISO(startTime)
        .setZone(timezone)
        .minus(Number(RSVPConfig.parkingSession.cancellationDeltaMs))

    return (
      <div className={styles.modifierWrapper}>
        <div className={styles.changeReservationCopy}>
          {showChangePlateButton && (
            <div>
              {countExplanation}
              <br />
              Please ensure that you have selected the actual vehicle plate that
              will be parking at {address.street},&nbsp;
              {address.city}.
            </div>
          )}
          <br />
          {t("parkingSession.cancellation")}
        </div>
        <div className={styles.modifiers}>
          {showChangePlateButton && (
            <Link
              to={generateRedirectUrl(`/sessions/${id}/change-vehicle`, {
                destUrl: `${process.env.REACT_APP_RSVP_BASE}/parking-reservation/${parkingSessionId}`,
                originUrl: `${process.env.REACT_APP_RSVP_BASE}/consume/parking-reservation/${parkingSessionId}`
              })}
              className={classNames(
                "btn",
                "btn-outline-dark",
                "fw-bold",
                styles.modifierButton
              )}
              target="_blank"
            >
              Change Plate
            </Link>
          )}
          <button
            className={classNames(
              "btn",
              "btn-outline-dark",
              "fw-bold",
              styles.modifierButton
            )}
            type="button"
            onClick={cancel}
            disabled={!cancellationAllowed}
          >
            Cancel Parking Reservation
          </button>
        </div>
      </div>
    )
  }

  const hasPromoCodes = !isEmpty(
    get(currentCodesData, "currentAccountPromoCodes")
  )
  const anotherPurchaseUrl = hasPromoCodes
    ? "/parking-codes"
    : "/select-parking"

  return (
    <div className={styles.page}>
      <Helmet title={pageTitle} />
      <div className={styles.hero}>
        <Hero>
          <Hero.Headline className={styles.headline}>
            Current Vehicle
          </Hero.Headline>
          <div className={styles.plate}>{plateNumber}</div>
          <div className="container-sm">
            <div className="row justify-content-center">
              <div className="col-md-8 col-xl-6">{renderModifiers()}</div>
            </div>
          </div>
        </Hero>
      </div>
      <div className="container-sm">
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <div className={styles.content}>
              {renderStatus()}
              {promoCode && (
                <Card
                  className={classNames(
                    styles.card,
                    styles.detailsCard,
                    styles.promoCard
                  )}
                >
                  <div>
                    <div className={styles.detailsHeader}>Description</div>
                    <div className={styles.detailsCopy}>
                      {promoCode.shortDesc}
                    </div>
                  </div>
                  <div>
                    <div className={styles.detailsHeader}>Parking Code</div>
                    <div className={styles.detailsCopy}>
                      {toUpper(promoCode.redeemCode)}
                    </div>
                  </div>
                </Card>
              )}
              <Card className={classNames(styles.card, styles.detailsCard)}>
                <div className={styles.detailsGrid}>
                  <img src={clock} alt="clock" />
                  <div>
                    <div className={styles.detailsHeader}>Date</div>
                    <div className={styles.detailsCopy}>
                      {formatDate(startTime)} - {formatDate(endTime)}
                    </div>
                  </div>
                  {!isEmpty(rateName) && (
                    <>
                      <img src={vehicle} alt="clock" />
                      <div>
                        <div className={styles.detailsHeader}>Parking Rate</div>
                        <div className={styles.detailsCopy}>{rateName}</div>
                      </div>
                    </>
                  )}
                  <img src={mapPin} alt="map" />
                  <div>
                    <div className={styles.detailsHeader}>Location</div>
                    <div className={styles.detailsCopy}>
                      {address.street},&nbsp;
                      {address.city}
                    </div>
                    <a
                      href={googleMapsUrl(coords)}
                      className={styles.detailsSmallCopy}
                    >
                      <img src={directions} alt="directions" />
                      &nbsp; View Directions
                    </a>
                  </div>
                  <img src={receipt} alt="receipt" />
                  <div>
                    <div className={styles.detailsHeader}>Receipt #</div>
                    <div className={styles.detailsCopy}>
                      {toUpper(invoiceNumber)}
                    </div>
                    <Link
                      to={avoidPreRedirectUrl(`/receipts/${txnId}`, {
                        destUrl: `${process.env.REACT_APP_RSVP_BASE}`
                      })}
                      target="_blank"
                      className={styles.detailsSmallCopy}
                    >
                      View Receipt
                    </Link>
                  </div>
                </div>
              </Card>
              <ParkingInstructionsCard />
              <div className="d-grid">
                <Link
                  to={anotherPurchaseUrl}
                  className="btn btn-primary btn-lg fw-bold"
                >
                  Reserve Another Parking Spot
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorToast />
    </div>
  )
}

export default ParkingSession
