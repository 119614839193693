import { ApolloClient, HttpLink, from } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

import isEmpty from "lodash/isEmpty"
import { AUTH_KEY, AUTH_REDIRECT_URL_KEY } from "shared/constants"
import cache, { typeDefs, errorsVar, isLoggedInVar } from "./cache"

const USER_FINGERPRINT_PARAM = "honkGUID"

const readUserFingerprint = () => {
  let userFingerprint = localStorage.getItem(USER_FINGERPRINT_PARAM)
  if (isEmpty(userFingerprint)) {
    userFingerprint =
      Math.random().toString(36).substring(2, 30) +
      Math.random().toString(36).substring(2, 30)
    localStorage.setItem(USER_FINGERPRINT_PARAM, userFingerprint)
  }

  return userFingerprint
}

// Custom fetch to append honkGuid for easy fingerprinting in logs
const fetchWithFingerprint = (uri, options) => {
  const url = new URL(uri)
  url.searchParams.append(USER_FINGERPRINT_PARAM, readUserFingerprint())

  return fetch(url, options)
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_KEY)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "X-AUTHENTICATION": token ? token.toString() : ""
    }
  }
})

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_PLATFORM_BASE}/graphql`,
  fetch: fetchWithFingerprint
})

export const clearOnLogout = (clientContext) => {
  isLoggedInVar(false)
  localStorage.removeItem(AUTH_KEY)
  localStorage.removeItem(AUTH_REDIRECT_URL_KEY)
  clientContext.clearStore()
}

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    !isEmpty(graphQLErrors) &&
    graphQLErrors[0]?.message === "missing_authentication"
  ) {
    // eslint-disable-next-line
    clearOnLogout(client)
    window.location.replace("/")
  }
  if (graphQLErrors || networkError) {
    errorsVar(["general_error"])
  } else {
    errorsVar(null)
  }
})

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
  typeDefs
})

export default client
