import errorImg from "assets/images/error.png"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import styles from "./GenericError.module.scss"

const GenericError = () => {
  const [t] = useTranslation()
  return (
    <div className={styles.page}>
      <a className={styles.header} href="/">
        {t("name")} Parking
      </a>
      <img
        src={errorImg}
        alt="error"
        className={classNames(styles.errorImg, "mx-auto d-block pb-4")}
      />
      <div className={styles.whoops}>Whoops!</div>
      <div className={styles.errorMessage}>
        Looks like something went wrong. Try again and we’ll ski you soon!
      </div>
      <div className="container-sm">
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <div className="d-grid">
              <a className="btn btn-primary btn-lg fw-bold" href="/">
                Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenericError
