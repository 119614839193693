const config = {
  name: "oakview",
  features: {
    accessCodes: true,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: true,
    userPhoneNumber: false,
    userName: false
  },
  parkingSession: {
    // that day, 2 hours before each start time
    // e.g., if the parking session starts at 9am and 1pm, users can cancel before 7am and 11am respectively
    cancellationDeltaMs: 7200000,
    // Start time offset from midnight, i.e. 9 am and 1pm
    // This portal will have VALID BETWEEN rates that start at 9am and 1pm
    startOffsetHrs: 9,
    // Start date offset from today (0 = today)
    startOffsetDate: 0,
    // End date offset from start time
    endOffsetDate: 90
  },
  signUp: {
    firstNameRequired: false,
    lastNameRequired: false
  }
}
export default config
