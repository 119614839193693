const config = {
  name: "dillon",
  features: {
    accessCodes: false,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true,
    userName: false
  },
  parkingSession: {
    // 24 hours, i.e 2 pm of prior day
    cancellationDeltaMs: 86400000,
    // Start time offset from midnight, i.e. 10 am
    startOffsetHrs: 14,
    // Start date offset from today (0 = today)
    startOffsetDate: 0,
    // End date offset from start time
    endOffsetDate: 300
  },
  signUp: {
    firstNameRequired: false,
    lastNameRequired: false
  }
}
export default config
