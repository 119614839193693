const messages = {
  default: "Something went wrong. Please try again.",
  invalid_promo_code: "Invalid code entered. Please try again.",
  invalid_login: "Invalid login credentials. Please try again.",
  missing_password: "Password can't be blank.",
  missing_email_address: "Email address can't be blank.",
  email_address_exists: "Email address already taken.",
  invalid_email_address: "Invalid email address. Please try again.",
  promo_code_claimed: "This code has already been claimed.",
  user_periodic_limit:
    "This code has already been used up to its limit for this time period.",
  // Promo Claim Validations
  over_account_rate_limit: "Number of active reservations has been reached.",
  invalid_account: "This parking code is not allowed for this account",
  user_limit:
    "This parking code has been used the maximum number of times by this account",
  promo_limit: "This parking code has been used the maximum number of times",
  invalid_zone: "This parking code cannot be used at this zone",
  invalid_portal: "This parking code cannot be used at this portal",
  invalid_company:
    "This parking code cannot be used at zones operated by this company",
  first_time_only: "This parking code is only for first-time purchases",
  over_allocated_amount: "This parking code has reached is maximum spend limit",
  insufficient_password:
    "Your password must be at least 8 characters long and include at least three of the following: uppercase letters, lowercase letters, numbers, and special characters"
}

const proxyHandler = {
  get: (obj, prop) => {
    return prop in obj ? obj[prop] : obj.default
  }
}

export const errorMessages = new Proxy(messages, proxyHandler)
