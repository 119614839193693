import { InMemoryCache, gql, makeVar } from "@apollo/client"
import { AUTH_KEY, PROMO_CODE_KEY } from "shared/constants"
import { DateTime } from "luxon"
import difference from "lodash/difference"

export const isLoggedInVar = makeVar(!!localStorage.getItem(AUTH_KEY))
// PromoCode to be attached to account
export const accountPromoCodeVar = makeVar(localStorage.getItem(PROMO_CODE_KEY))
// PromoCode to be attached to cart
export const cartPromoCodeVar = makeVar(null)
export const cartIdVar = makeVar(null)
export const selectedDayVar = makeVar(null)
export const errorsVar = makeVar(null)
export const zoneIdVar = makeVar(null)

// Client-side schema for local-only details
export const typeDefs = gql`
  type UserSession {
    oaTag: String
  }

  extend type Query {
    isLoggedIn: Boolean!
    userSession: UserSession
    promoCode: String
  }
`

const cache = new InMemoryCache({
  typePolicies: {
    PromoCode: {
      fields: {
        isExpired: {
          read(_, { readField }) {
            const expiry = readField("expiry")
            return DateTime.fromISO(expiry) < DateTime.now()
          }
        }
      }
    },
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar()
          }
        },
        pastAccountPromoCodes: {
          read(_pastAccountPromoCodes, { readField, variables }) {
            const { rsvpPortalId } = variables
            const canonical =
              readField({
                fieldName: "v2AccountPromoCodes",
                args: { rsvpPortalId }
              }) || []

            return canonical.filter((pastAcp) => {
              return readField("isExpired", pastAcp)
            })
          }
        },
        currentAccountPromoCodes: {
          read(_currentAccountPromoCodes, { readField, variables }) {
            const { rsvpPortalId } = variables
            const canonical =
              readField({
                fieldName: "v2AccountPromoCodes",
                args: { rsvpPortalId }
              }) || []

            const past =
              readField({
                fieldName: "pastAccountPromoCodes",
                args: { rsvpPortalId }
              }) || []

            return difference(canonical, past)
          }
        }
      }
    },
    Rate: {
      keyFields: ["hashid"]
    },
    Zone: {
      keyFields: ["hashid"]
    },
    ParkingSession: {
      keyFields: ["hashid"]
    }
  }
})

export default cache
