import "./App.css"
import GenericError from "components/GenericError"
import AppSignal from "@appsignal/javascript"
import { ErrorBoundary } from "@appsignal/react"
import ReactGA from "react-ga4"
import { useEffect } from "react"
import Router from "./router"

const appsignal = new AppSignal({
  key: process.env.REACT_APP_APPSIGNAL_KEY,
  namespace: process.env.REACT_APP_WHITELABEL
})
function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID)
  }, [])

  return (
    <ErrorBoundary instance={appsignal} fallback={() => <GenericError />}>
      <Router />
    </ErrorBoundary>
  )
}

export default App
