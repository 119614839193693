import PropTypes from "prop-types"
import { useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { cartIdVar, errorsVar, cartPromoCodeVar } from "cache"
import { generateRedirectUrl } from "shared/helpers/url"
import {
  isAnyPublicRateUnavailable,
  getUnavailableRates,
  isNotificationRate,
  isSoldOut
} from "shared/helpers/availability"

import { BEHAVIOUR_TYPE, STEPS, MESSAGE_CARD_TYPES } from "shared/constants"
import FullPageLoader from "components/shared/FullPageLoader"
import RateCard from "./RateCard"
import UnavailableRatesCard from "./UnavailableRatesCard"
import ExpandableCard from "../ExpandableCard"
import { AddRateToCart, ValidateCartPromoCode } from "../operations"

import styles from "./SelectRate.module.scss"

const SelectRate = ({
  step,
  rates,
  rateLoading,
  cartLoading,
  detailWithRatesData
}) => {
  const history = useHistory()
  const cartId = cartIdVar()

  const [addRateToCart, { loading: addRateLoading }] =
    useMutation(AddRateToCart)
  const [validateCartPromoCode, { loading: validateLoading }] = useMutation(
    ValidateCartPromoCode
  )

  const handleSubmit = async (rateId) => {
    const {
      data: {
        addRateToCart: { cart, errors: addRateErrors }
      }
    } = await addRateToCart({
      variables: {
        input: { rateId, cartId }
      }
    })

    const {
      data: {
        validateCartPromoCode: { errors: validateErrors }
      }
    } = await validateCartPromoCode({ variables: { input: { id: cartId } } })

    const errors = [...addRateErrors, ...validateErrors]
    if (!isEmpty(errors)) {
      errorsVar(errors)
      return
    }

    errorsVar(null)
    const location = generateRedirectUrl(`/checkout/${cart.id}`, {
      destUrl: `${process.env.REACT_APP_RSVP_BASE}/post-purchase`,
      originUrl: `${process.env.REACT_APP_RSVP_BASE}/consume/parking-codes`
    })

    // Check if cartPromoCodeVar has a value and set it to null if it does
    // This is to ensure that after user selected a rate, we reset the promo code
    if (cartPromoCodeVar()) {
      cartPromoCodeVar(null)
    }

    history.push(location)
  }

  const renderCardContent = () => {
    const cartRates = get(rates, "v2CartRates", [])
    const isRatesSoldOut = isSoldOut(detailWithRatesData)
    const unavailableMessageType = isRatesSoldOut ? MESSAGE_CARD_TYPES.soldOut : MESSAGE_CARD_TYPES.unavailable

    // Return a card with a message if there are no rates available
    if (isEmpty(cartRates)) {
      return <UnavailableRatesCard type={unavailableMessageType} />
    }

    // Check if any rates are unavailable (from the PublicParkingAvailability)
    const unavailableRates = isAnyPublicRateUnavailable(detailWithRatesData)
      ? getUnavailableRates(detailWithRatesData)
      : []

    return (
      <>
        {cartRates.map((rate) => {
          const { behaviourType, hashid, description } = rate

          // If the rate is a notification rate, render the unavailable rates card
          if (behaviourType === BEHAVIOUR_TYPE.notification) {
            return (
              <UnavailableRatesCard
                type={MESSAGE_CARD_TYPES.notification}
                text={description}
                key={hashid}
              />
            )
          }

          return (
            <RateCard
              rate={rate}
              key={hashid}
              onClick={() => handleSubmit(hashid)}
            />
          )
        })}
        {
          // Display sold out rate card
          unavailableRates.length > 0 &&
            !isNotificationRate(unavailableRates) &&
            unavailableRates.map((unavailableRate) => {
              const { hashid, available } = unavailableRate
              return (
                <RateCard
                  rate={unavailableRate}
                  key={hashid}
                  className={styles.unavailableCard}
                  available={available}
                />
              )
            })
        }
      </>
    )
  }

  if (addRateLoading || validateLoading)
    return (
      <FullPageLoader message="Redirecting to HONK to complete your purchase" />
    )

  return (
    <div className="h-100">
      <ExpandableCard
        step={step}
        currentStep={STEPS.rate}
        title="3. Parking rate"
        isLoading={rateLoading || isEmpty(rates) || cartLoading}
      >
        <div className={styles.wrapper}>{renderCardContent()}</div>
      </ExpandableCard>
    </div>
  )
}

SelectRate.propTypes = {
  step: PropTypes.string,
  rates: PropTypes.object,
  activePromoCode: PropTypes.bool,
  rateLoading: PropTypes.bool,
  cartLoading: PropTypes.bool,
  detailWithRatesData: PropTypes.object
}

export default SelectRate
