import { useEffect } from "react"
import { useQuery, makeVar } from "@apollo/client"
import { useHistory, useLocation } from "react-router-dom"
import { AUTH_REDIRECT_URL_KEY } from "shared/constants"
import { IsUserLoggedIn } from "./operations"

// Is this dumb? Can we just use the reactive var?
export const useLoginState = () => {
  const {
    data: { isLoggedIn }
  } = useQuery(IsUserLoggedIn)
  return [isLoggedIn]
}

const isValidHttpUrl = (string) => {
  let url
  try {
    // If you only pass a relative url, you need the second argument
    // to be a valid protocol + host. We aren't using HOST anyway,
    // it's just for URL validation.
    // if `string` is a fully-qualified url, the second argument is ignored,
    // so this should work with both relative and absolute urls .
    url = new URL(string, `https://${process.env.HOST}`)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

const defaultUrl = "/"
const getInitialUrl = () => {
  let url = localStorage.getItem(AUTH_REDIRECT_URL_KEY)
  if (!isValidHttpUrl(url)) {
    url = defaultUrl
  }

  return url
}

export const urlAfterAuth = makeVar(getInitialUrl())

export const useAuthRedirectEffect = () => {
  const [isLoggedIn] = useLoginState()
  const history = useHistory()

  useEffect(() => {
    if (isLoggedIn) {
      const destination = urlAfterAuth() || defaultUrl
      history.replace(destination)
      localStorage.removeItem(AUTH_REDIRECT_URL_KEY)
      urlAfterAuth(defaultUrl)
    }
  }, [history, isLoggedIn])
}

export const useForceAuthEffect = (options = {}) => {
  const [isLoggedIn] = useLoginState()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const defaults = {
      authSuccessUrl: `${location.pathname}${location.search}`,
      authUrl: "/signup"
    }

    const config = { ...defaults, ...options }

    if (!isLoggedIn) {
      localStorage.setItem(AUTH_REDIRECT_URL_KEY, config.authSuccessUrl)
      urlAfterAuth(config.authSuccessUrl)
      history.replace(config.authUrl)
    }
  }, [isLoggedIn, history, options, location.pathname, location.search])
}
