import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./FullPageLoader.module.scss"

const FullPageLoader = ({ message, copy }) => {
  return (
    <div className="vh-100 d-flex align-items-center justify-content-center px-5 fixed-top bg-white">
      <div className="d-flex flex-column align-items-center justify-content-center text-center">
        <div
          className={classNames(
            styles.spinner,
            "spinner-border text-primary mb-2"
          )}
          role="status"
        />
        <div className={classNames(styles.headline, "text-center")}>
          {message}
        </div>
        {copy}
      </div>
    </div>
  )
}

FullPageLoader.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default FullPageLoader
