import classNames from "classnames"
import { Link } from "react-router-dom"
import { useLoginState } from "components/Login/hooks"
import styles from "./Nav.module.scss"

const LoginProfileIcon = () => {
  const [isLoggedIn] = useLoginState()
  return (
    <>
      {!isLoggedIn && (
        <Link
          to="/login"
          className={classNames(
            styles.loginProfileLink,
            "text-decoration-none"
          )}
        >
          Login
        </Link>
      )}
      {isLoggedIn && (
        <Link to="/settings" className={styles.loginProfileLink}>
          <i className={classNames("bi-list", styles.hamburgerIcon)} />
        </Link>
      )}
    </>
  )
}

export default LoginProfileIcon
