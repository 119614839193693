import FullPageLoader from "components/shared/FullPageLoader"
import { useEffect } from "react"
import { useLocation, Redirect } from "react-router-dom"
import qs from "query-string"
import isEmpty from "lodash/isEmpty"
import Helmet from "react-helmet"
import { AUTH_KEY } from "shared/constants"
import { useForceAuthEffect } from "components/Login/hooks"

const host = process.env.REACT_APP_COREAPP_BASE

const PreRedirect = () => {
  useForceAuthEffect()
  // expects oaTag param and r param (for the redirect post-consume)
  const { pathname, search } = useLocation()
  const rawQuery = qs.parse(search)
  const { oaTag, r } = rawQuery

  useEffect(() => {
    const url = new URL(`/consume`, host)
    url.search = qs.stringify({ oaTag, r })

    const timer = setTimeout(() => {
      if (r && oaTag) {
        window.location.assign(url)
      }
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [r, oaTag])

  const needsAuthParam = isEmpty(oaTag)

  if (needsAuthParam)
    return (
      <Redirect
        to={{
          pathname,
          search: qs.stringify({ oaTag: localStorage.getItem(AUTH_KEY), r })
        }}
      />
    )

  return (
    <>
      <Helmet title="Redirecting to HONK" />
      <FullPageLoader
        message="Redirecting to HONK"
        copy="Continue your parking reservation with HonkMobile"
      />
    </>
  )
}

export default PreRedirect
