import PropTypes from "prop-types"
import classNames from "classnames"
import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import toUpper from "lodash/toUpper"
import every from "lodash/every"
import get from "lodash/get"
import includes from "lodash/includes"
import filter from "lodash/filter"
import map from "lodash/map"
import { DateTime } from "luxon"
import Card from "components/shared/Card"
import { cartPromoCodeVar, selectedDayVar } from "cache"
import { useHistory } from "react-router-dom"
import {
  formatToDayStartISO,
  formatShortDate
} from "shared/formatting/datetime"
import { useRsvpPortal } from "components/ParkingSelection/hooks"

import styles from "./PromoCodeCard.module.scss"

const PromoCodeCard = ({ promoCode }) => {
  const {
    redeemCode,
    shortDesc,
    totalUses,
    totalUsesCount,
    promoCodesRates,
    startDate,
    expiry,
    isExpired,
    locationIds
  } = promoCode
  const isTrackingRateLimits = !isEmpty(promoCodesRates)
  const isActiveLimitReached = every(
    promoCodesRates,
    (pcr) => pcr.activeSessionCount >= pcr.activeSessionLimit
  )
  const { data: portalData } = useRsvpPortal()

  const portalZones = get(portalData, "rsvpPortal.zones", [])
  const portalZonesHashid = portalZones.map((zone) => zone.hashid)
  const timezone = get(portalZones, "[0].timezone")

  const filteredPromoCodesRates = promoCodesRates?.filter((pcr) => {
    const zoneHashid = get(pcr.rate, "zone.hashid", null)
    return includes(portalZonesHashid, zoneHashid)
  })
  const hasActiveUsesRemaining = !isTrackingRateLimits || !isActiveLimitReached

  const isTrackingTotalUses = !isNil(totalUses)
  const hasTotalUsesRemaining =
    !isTrackingTotalUses || totalUsesCount < totalUses

  const isPurchaseAllowed = hasTotalUsesRemaining && hasActiveUsesRemaining

  const history = useHistory()

  const handleClick = async () => {
    const isoStartDate = DateTime.fromISO(startDate)
    const promoStartDate = formatToDayStartISO(isoStartDate, { timezone })
    cartPromoCodeVar(redeemCode)
    selectedDayVar(promoStartDate)
    history.push("/select-parking")
  }

  // "Valid at" shows zones that are available for the promo code AND the portal
  // If promo code is available for Zone A, B, and C but if the portal has Zone B,
  // then only Zone B will be shown.
  const validZones = () => {
    if (isEmpty(locationIds) || isEmpty(portalZones)) return ""

    // Filter portalZones to include only those with zoneIds present in locationIds
    const matchingZones = filter(portalZones, (zone) =>
      includes(locationIds, zone.zoneId)
    )

    // Get the street addresses of matching zones
    const matchingAddresses = map(matchingZones, "address.street")

    return matchingAddresses.join(", ")
  }

  const errorCard = ({ title, message }) => (
    <div className={classNames("d-flex p-2 mt-3", styles.callout)}>
      <div className="pe-3 d-flex align-items-center">
        <i className="fs-1 text-primary bi bi-exclamation-circle" />
      </div>
      <div className="d-flex flex-column justify-content-center">
        <div className="fw-bold mb-1">{title}</div>
        <div className={styles.calloutText}>{message}</div>
      </div>
    </div>
  )

  let errorMessage = null
  if (!hasTotalUsesRemaining) {
    errorMessage = errorCard({
      title: "Usage Limit Reached",
      message: "You have used this parking code the maximum number of times."
    })
  } else if (!hasActiveUsesRemaining) {
    errorMessage = errorCard({
      title: "All Active Reservations Used",
      message:
        "You have reached the active reservation limit for this pass. Additional reservations can be made after your current reservation(s) are redeemed."
    })
  }

  return (
    <Card className={classNames(styles.card)}>
      <div className={styles.section}>
        <h5 className={styles.cardHeader}>{shortDesc}</h5>
        <div className={classNames(styles.cardRow)}>
          <p>Parking Code</p>
          <p className={styles.info}>{toUpper(redeemCode)}</p>
        </div>
        <div className={classNames(styles.cardRow)}>
          <p>Valid at</p>
          <p className={styles.info}>{validZones()}</p>
        </div>
        <div className={classNames(styles.cardRow)}>
          <p>Expiry</p>
          <p className={styles.info}>{formatShortDate(expiry, { timezone })}</p>
        </div>
        {isTrackingTotalUses && (
          <div className={classNames(styles.cardRow)}>
            <p>Redemptions Left</p>
            <p className={styles.info}>
              {totalUsesCount}/{totalUses}
            </p>
          </div>
        )}
        {errorMessage}
      </div>
      {!isExpired && (
        <>
          <div className={classNames(styles.section, styles.lowerContainer)}>
            {!isEmpty(filteredPromoCodesRates) && (
              <div className={styles.rates}>
                <p className={styles.ratesHeader}>Active Reservations</p>
                {filteredPromoCodesRates.map((pcr) => {
                  const {
                    activeSessionCount,
                    activeSessionLimit,
                    rate: { description, hashid }
                  } = pcr
                  return (
                    <div
                      key={hashid}
                      className={classNames(
                        styles.cardRow,
                        styles.activeReservation
                      )}
                    >
                      <p>{description}</p>
                      <p className={styles.info}>
                        {activeSessionCount}/{activeSessionLimit}
                      </p>
                    </div>
                  )
                })}
              </div>
            )}
            <button
              type="button"
              disabled={!isPurchaseAllowed}
              className={classNames(
                styles.button,
                "w-100 btn btn-primary btn-sm fw-bold"
              )}
              onClick={handleClick}
            >
              Reserve Parking
            </button>
          </div>
        </>
      )}
    </Card>
  )
}

PromoCodeCard.propTypes = {
  promoCode: PropTypes.shape({
    redeemCode: PropTypes.string.isRequired,
    shortDesc: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    expiry: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    totalUses: PropTypes.number,
    totalUsesCount: PropTypes.number.isRequired,
    isExpired: PropTypes.bool,
    locationIds: PropTypes.arrayOf(PropTypes.string),
    promoCodesRates: PropTypes.arrayOf(
      PropTypes.shape({
        activeSessionCount: PropTypes.number.isRequired,
        activeSessionLimit: PropTypes.number.isRequired,
        rate: PropTypes.shape({
          description: PropTypes.string.isRequired,
          hashid: PropTypes.string.isRequired
        })
      })
    )
  })
}

export default PromoCodeCard
